import React from 'react';

const Unique = ({selected, isPrimaryKey}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.8881 23.0158C21.5974 23.0158 21.3921 22.731 21.484 22.4552C22.0357 20.8002 20.9177 19.0588 19.1832 18.8715L18.5059 18.7984C17.872 18.7299 17.3027 19.1883 17.2342 19.8222C17.1658 20.4561 17.6242 21.0255 18.2581 21.0939L18.9353 21.167C19.2054 21.1962 19.3795 21.4674 19.2936 21.7251C18.7034 23.4959 20.0214 25.3247 21.8881 25.3247L23.6359 25.3247C24.2735 25.3247 24.7904 24.8078 24.7904 24.1702C24.7904 23.5326 24.2735 23.0158 23.6359 23.0158L21.8881 23.0158Z"
                  fill={isPrimaryKey ? "#3da71d" : "#295bd5"}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.08688 4.78691C5.73922 4.73527 6.3121 5.19578 6.36646 5.8155L7.17158 14.9939C7.42754 17.9118 9.99518 20.1563 13.0773 20.1563H13.4756C16.5578 20.1563 19.1254 17.9118 19.3814 14.9939L20.1865 5.8155C20.2408 5.19578 20.8137 4.73527 21.4661 4.78691C22.1184 4.83855 22.6031 5.3828 22.5488 6.00252L21.7437 15.1809C21.3853 19.266 17.7906 22.4082 13.4756 22.4082H13.0773C8.76231 22.4082 5.16762 19.266 4.80928 15.1809L4.00416 6.00252C3.9498 5.3828 4.43455 4.83855 5.08688 4.78691Z"
                  fill={isPrimaryKey ? "#3da71d" : "#295bd5"}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.2764 4.26565C12.4784 4.26565 11.8315 4.91257 11.8315 5.71059C11.8315 6.50861 12.4784 7.15553 13.2764 7.15553C14.0745 7.15553 14.7214 6.50861 14.7214 5.71059C14.7214 4.91257 14.0745 4.26565 13.2764 4.26565ZM9.56586 5.71059C9.56586 3.66129 11.2271 2 13.2764 2C15.3257 2 16.987 3.66129 16.987 5.71059C16.987 7.75989 15.3257 9.42117 13.2764 9.42117C11.2271 9.42117 9.56586 7.75989 9.56586 5.71059Z"
                  fill={isPrimaryKey ? "#3da71d" : "#295bd5"}/>
        </svg>

    );
};

export default Unique;