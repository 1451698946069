export const getRandomColor = (objLen) => {
    const contrast = objLen ? objLen % 3 : 0;
    let colorsCoif = [];
    for (let i = 0; i < 3; i++) {
        colorsCoif[i] = Math.floor(Math.random() * 255) ;
        if (contrast === i) colorsCoif[i] = 255;
    }
    return `#${colorsCoif[0].toString(16).padStart(2, '0')}${colorsCoif[1].toString(16).padStart(2, '0')}${colorsCoif[2].toString(16).padStart(2, '0')}`;
}

export const copyObject = (object) => {
  return JSON.parse(JSON.stringify(object));
}