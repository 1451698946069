import React from 'react';

const MiniUnique = ({color}) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.28865 8.77273L8.58199 8.8044C9.01608 8.85127 9.2959 9.28712 9.15783 9.70133V9.70133C9.0101 10.1445 9.33998 10.6022 9.80716 10.6022L10.5642 10.6022"
                stroke={color ? color : "#828181"} strokeLinecap="round"/>
            <path
                d="M2.59985 2.63788L2.93949 6.71358C3.06907 8.26845 4.36887 9.46444 5.92913 9.46444H6.01313H6.09714C7.6574 9.46444 8.9572 8.26845 9.08677 6.71358L9.42642 2.63788"
                stroke={color ? color : "#828181"} strokeLinecap="round"/>

            <circle cx="6.01313" cy="2.63788" r="1.13776" stroke={color ? color : "#828181"}
                    shapeRendering="crispEdges"/>

        </svg>


    );
};

export default MiniUnique;